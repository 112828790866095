import { Box, IconButton, Link, useMediaQuery } from '@mui/material';
import AvatarGroupImage from '../../../../assets/images/users/avatar-group.png';
// import store from '../../../../basic-store'
// let AvatarGroupImage;
// import(`../../../../assets/images/users/avatar-group--${store.projectName}.png`).then((module) => {
//   AvatarGroupImage = module.default
// })

import Cx from '../../../../assets/images/users/cx.png';
import Search from './Search';
import Profile from './Profile';
/* import Notification from './Notification'; */
import MobileSection from './MobileSection';

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <>
      {!matchesXs && <Search />}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

      {
        <IconButton
          component={Link}
          href="https://www.mozart-autos.com"
          target="_blank"
          disableRipple
          color="Secondary"
          title="Mozart Auto"
          sx={{ color: 'text.primary', bgcolor: 'grey.100', mr: 1 }}
        >
          <img src={AvatarGroupImage} alt="Avatar Group" style={{ width: '28px', height: '28px' }} />
        </IconButton>
      }
      {
        <IconButton
          component={Link}
          href="https://mozart-autos.on3cx.fr/#/app/system_status/all"
          target="_blank"
          disableRipple
          color="secondary"
          title="3CX"
          sx={{ color: 'text.primary', bgcolor: 'grey.100' }}
        >
          <img src={Cx} alt="Avatar Group" style={{ width: '28px', height: '14px' }} />
        </IconButton>
      }

      {/* <Notification /> */}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
