import { axiosInstance } from '../axios/axios-config';

const CallGroupsRequest = {
  getAllQueues: async () => {
    if (!sessionStorage.getItem('userData')) {
      return []
    }
    try {
      let url = '/queues'
      if (sessionStorage.getItem('limitedSda')) {
        url += '?limitedSda=' + sessionStorage.getItem('limitedSda')
      }
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      console.error('Erreur lors de l\'obtention des groupes d\'appels :', error);
      throw error;
    }
  },
  getQueueByName: async (name, { dateStart, dateEnd, agent } = {}) => {
    if (!sessionStorage.getItem('userData')) {
      return []
    }
    try {
      const response = await axiosInstance.get(`/queues/${name}`, {
        params: { dateStart, dateEnd, agent }
      });
      return response.data;
    } catch (error) {
      console.error('Erreur lors de l\'obtention des détails du groupe d\'appels :', error);
      throw error;
    }
  }
};


// const fakeCallGroupsRequest = {
//   getAllQueues: async () => {
//     console.error('Erreur lors de l\'obtention des groupes d\'appels :');
//   },
//   getQueueByName: async () => {
//     console.error('Erreur lors de l\'obtention des groupes d\'appels :');
//   }
// };

// let CallGroupsRequest;
// if (sessionStorage.getItem('userData') != null) {
//   CallGroupsRequest = realCallGroupsRequest
// }
// else {
//   CallGroupsRequest = fakeCallGroupsRequest
// }

export { CallGroupsRequest }
