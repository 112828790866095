import React, { createContext, useContext, useState } from 'react';

const DashboardContext = createContext();

export const useDashboard = () => useContext(DashboardContext);

export const DashboardProvider = ({ children }) => {
  const [selectedDate, setSelectedDate] = useState([null, null]);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [selectedSda, setSelectedSda] = useState(null);
  const [selectedQueue, setSelectedQueue] = useState(null);

  return (
    <DashboardContext.Provider value={{
        selectedDate,
        setSelectedDate,
        selectedAgent,
        setSelectedAgent,
        selectedSda,
        setSelectedSda,
        selectedQueue,
        setSelectedQueue
    }}>
      {children}
    </DashboardContext.Provider>
  );
};
