import PropTypes from 'prop-types';

import { ButtonBase } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import Logo from './Logo'

// import store from '../../basic-store';

// let Logo;
// import(`./Logo--${store.projectName}`).then((module) => {
//   Logo = module.default
// })
import { activeItem } from 'store/reducers/menu';

const LogoSection = ({ sx }) => {
    const { defaultId } = useSelector((state) => state.menu);
    const dispatch = useDispatch();
    return (
      <ButtonBase
        disableRipple
        component="div"
        onClick={() => dispatch(activeItem({ openItem: [defaultId] }))}
        sx={{ ...sx, cursor: 'default' }}
      >
        <Logo />
      </ButtonBase>
    );
  };

LogoSection.propTypes = {
  sx: PropTypes.object,
  to: PropTypes.string
};

export default LogoSection;
