import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

let MainRoutes;

  const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));
  const GestionAppels = Loadable(lazy(() => import('pages/gestion-appels')));
  const GestionAgents = Loadable(lazy(() => import('pages/gestion-agents')));
  const JournalAppels = Loadable(lazy(() => import('pages/gestion-du-temps')));
  
  const AddUsersPage = Loadable(lazy(() => import('pages/gestion-utilisateurs/AddUsersPage')));
  
  const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));
  
  const ParametresCompte = Loadable(lazy(() => import('pages/extra-pages/ParametresCompte')));
  
  const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
  const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

  MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="/login" />
      },
      {
        path: 'dashboard',
        children: [
          {
            path: 'default',
            element: <DashboardDefault />
          },
          {
            path: 'gestion-appels',
            element: <GestionAppels />
          },
          {
              path: 'gestion-agents',
              element: <GestionAgents />
          },
          {
              path: 'gestion-du-temps',
              element: <JournalAppels />
          }
        ]
      },
      {
        path: 'sample-page',
        element: <SamplePage />
      },
      {
          path: 'addusers-page',
          element: <AddUsersPage />
        },
      {
        path: 'parametre-compte',
        element: <ParametresCompte />
      },
      /* {
        id: 'support',
        title: 'Support',
        type: 'item',
        url: 'https://outlook.office365.com/mail/deeplink/compose?mailtouri=mailto%3Acontact%40snsolutions.fr',
        icon: icons.QuestionCircleOutlined,
        external: true,
        target: true
      },
      {
        id: 'mention-legale',
        title: 'Mention légale',
        type: 'item',
        url: '/mention-legale',
        icon: icons.AuditOutlined
      },
      {
        id: 'confidentialite',
        title: 'Confidentialité',
        type: 'item',
        url: '/confidentialite',
        icon: icons.LockOutlined
      },
      {
        id: 'feedback',
        title: 'Feedback',
        type: 'item',
        url: '/feedback',
        icon: icons.CommentOutlined
      }, */
      {
        path: 'shadow',
        element: <Shadow />
      },
      {
        path: 'icons/ant',
        element: <AntIcons />
      }
    ]
  };

// }
// else {
//   MainRoutes = {
//     path: '/',
//     element: <MainLayout />,
//     children: [
//       {
//         path: '/',
//         element: <Navigate to="/login" />
//       }
//     ]
//   };
// }

export default MainRoutes;

