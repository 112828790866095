import { useQuery } from 'react-query';
import { getAgentsBySda } from '../request/agents.request';

function useGetAgentsBySda(sdaName, dateStart, dateEnd) {
  return useQuery(
    ['agentsBySda', sdaName, dateStart, dateEnd],
    () => getAgentsBySda(sdaName, dateStart, dateEnd),
    {
      enabled: !!sdaName && !!dateStart && !!dateEnd,
    }
  );
}

export default useGetAgentsBySda;
