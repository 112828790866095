import axios from 'axios';

// import store from '../basic-store'

// const baseURL = `https://mozart.snsolutions.fr/api/`;
const baseURL = `https://${location.hostname}/api/`; // In production we could use this instead of .env file !
// const baseURL = `https://${store.baseUrlPrefix}.snsolutions.fr/api`; // this works great as well, so we only have to change 1 file
// # https://mozart.snsolutions.fr/api/
// # https://stats3cx.snsolutions.fr/api/
// # https://siffrein.snsolutions.fr/api/
const axiosInstance = axios.create({ baseURL });

const getBase64Credentials = (username, password) => btoa(`${username}:${password}`);

const setAxiosAuthHeader = (username, password) => {
  axiosInstance.defaults.headers.common['Authorization'] = `Basic ${getBase64Credentials(username, password)}`;
};

export { axiosInstance, setAxiosAuthHeader };