import getDashboardMenu from './dashboard';
import getSupportMenu from './support';
import utilities from './utilities';

const loadMenuItems = () => {
  const dashboardMenu = getDashboardMenu();
  const supportMenu = getSupportMenu();
  console.log('Loaded dashboard menu:', dashboardMenu);
  console.log('Loaded support menu:', supportMenu);

  const menuItems = {
    items: [dashboardMenu, supportMenu, utilities]
  };

  console.log('Final menu items:', menuItems);
  return menuItems;
};

export default loadMenuItems;
