import { DashboardOutlined, PhoneOutlined, UsergroupAddOutlined } from '@ant-design/icons';

const icons = {
  DashboardOutlined,
  PhoneOutlined,
  UsergroupAddOutlined
};

const getDashboardMenu = () => {
  const isLimitedUser = sessionStorage.getItem('limitedSda') !== null;
  console.log('Is limited user:', isLimitedUser);

  return {
    id: 'group-dashboard',
    title: 'Navigation',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Tableau de bord',
        type: 'item',
        url: '/dashboard/default',
        icon: icons.DashboardOutlined,
        breadcrumbs: false
      },
      {
        id: 'gestion-appels',
        title: "Gestionnaire d'appels",
        type: 'item',
        url: '/dashboard/gestion-appels',
        icon: icons.PhoneOutlined,
        breadcrumbs: false
      },
      ...(!isLimitedUser ? [
        {
          id: 'gestion-agents',
          title: "Gestionnaire d'agents",
          type: 'item',
          url: '/dashboard/gestion-agents',
          icon: icons.UsergroupAddOutlined,
          breadcrumbs: false
        },
        {
          id: 'gestion-du-temps',
          title: "Gestion du temps",
          type: 'item',
          url: '/dashboard/gestion-du-temps',
          icon: icons.UsergroupAddOutlined,
          breadcrumbs: false
        }
      ] : [])
    ]
  };
};

export default getDashboardMenu;
