import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: '',
  isAuthenticated: false
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.email = action.payload.email;
      state.isAuthenticated = action.payload.isAuthenticated;
    },
    logoutUser(state) {
      state.email = '';
      state.isAuthenticated = false;
    }
  }
});

export const { setUser, logoutUser } = userSlice.actions;
export default userSlice.reducer;
