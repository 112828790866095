import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { DashboardProvider } from './context/DashboardContext';
import { setAxiosAuthHeader } from './axios/axios-config';

import 'simplebar/src/simplebar.css';
import 'assets/third-party/apex-chart.css';

import App from './App';
import { store } from 'store';
import reportWebVitals from './reportWebVitals';

const restoreSession = () => {
    const userData = JSON.parse(sessionStorage.getItem("userData"));
    if (userData) {
        setAxiosAuthHeader(userData.username, userData.password);
    }
};

restoreSession();

const container = document.getElementById('root');
const queryClient = new QueryClient();
const root = createRoot(container);

root.render(
    <StrictMode>
      <ReduxProvider store={store}>
        <BrowserRouter basename="/">
             {/* <BrowserRouter basename="/stats/"> */}
          <DashboardProvider>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </DashboardProvider>
        </BrowserRouter>
      </ReduxProvider>
    </StrictMode>
  );

reportWebVitals();
